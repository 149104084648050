.ask_me {
  .am_title {
    font-size: 1.2rem;
    color: #A5A3AE;
    padding:  0 0 0.5rem;
    margin-bottom: 1rem;
    margin-bottom: .5rem;
  }
  .am_chat {
    height: calc(100vh - 5.125rem);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    .am_chat_detail {
      max-width: 100%;
      flex: 1;
      box-shadow: 0 0.25rem 1.125rem #4b465c1a;
      border-radius: 0.375rem;
      &>*{
        border-radius: .375rem;
      }
    }
  }
}