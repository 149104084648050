.navigation {
    min-height: 100vh;

    .ant-layout-sider {
        height: 100vh;
        max-height: 100vh;
        box-shadow: 0 0.125rem 0.25rem #a5a3ae4d;
        user-select: none;
        padding: 0 .9375rem;
        background: var(--nav-bg) !important;
        transition: all 0.2s, background .5s;

        .ant-layout-sider-children {
            max-height: 100vh;
            display: flex;
            flex-direction: column;
            padding: 1rem 0;
        }

        &.ant-layout-sider-collapsed {
            padding: 0;
        }

        .logo_box {
           // margin: -22px -50px 8px -20px;
            /*height: 5.125rem;
      min-height: 5.125rem;*/
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: -1rem;

            .icon_box {
                width: 100%;
                overflow: hidden;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .logo {
                    width: 10rem;
                    margin-left: .75rem;
                    // width: 130.8px;
                    // max-width: 130.8px;
                    // min-width: 130.8px;
                }
                .line {
                    width: 8.5rem;
                    height: 2px;
                    background-color: #E7E7E7;
                    border-radius: 1px;
                    margin: 0.25rem 0 1.5rem;
                }
            }

            .nav_close {
                cursor: pointer;
            }

            &.collapsed {
                max-width: 40px;
                width: fit-content;
                margin: 0 auto;
                padding: 0;
                // justify-content: center;
                .icon_box {
                    min-width: 1.25rem;
                    max-width: 1.25rem;
                }

                .nav_close {
                    display: none;
                }
            }

            .anticon {
                display: none;
                font-size: 1rem;
                color: var(--power-color);
            }
        }
    }

    .ant-layout {
        background: unset;
    }

    .nav_power {
        font-size: 1rem;
        margin-top: .5rem;
        margin-left: 1rem;
        color: var(--power-color);
    }
}

@media (max-width: 1200px)  {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    transition: all .5s;
    &[open]{
      width: 100vw;
      background: #4b465c7f;
    }
  }
}