/* global.css */
@font-face {
  font-family: 'Euclid Circular A';
  src: url('./font/Euclid Circular A Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Euclid Circular A';
  src: url('./font/Euclid Circular A Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Euclid Circular A';
  src: url('./font/Euclid Circular A Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'YouYuan';
  src: url('./font/YouYuan.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* 重置 body 和 html 元素的样式 */
body,
html {
  margin: 0;
  padding: 0;
  font-size: 0.9375rem;
  line-height: 1.47;
  min-height: 100vh;
}
/*部分标签全局样式控制*/
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}
ul,
ol {
  list-style-type: none;
}
img {
  max-width: 100%;
  height: auto;
}
i {
  font-style: normal;
}
/* 设置全局链接的颜色和样式 */
a {
  color: var(--primary-color) !important;
  text-decoration: none;
  cursor: pointer;
}
*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0.3125rem;
  height: 0.3125rem;
}
*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 1rem;
  background: #ccc;
}
*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  display: none;
}
/* 其他全局样式规则 */
/* ... */
.ant-layout {
  background-color: #F8F7FA !important;
}
body .ant-btn {
  font-size: 0.9375rem;
  border-radius: 0.375rem;
  font-weight: 500;
  outline: unset !important;
}
body .ant-btn.ant-btn-primary {
  color: #fff;
  border-color: var(--primary-color);
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
body .ant-btn.ant-btn-primary:hover {
  color: #fff;
  background-color: var(--primary-color) !important;
}
body .ant-btn.ant-btn-primary:active {
  scale: 0.98;
  background-color: var(--primary-color) !important;
}
body .ant-btn.middle {
  padding: 0.25rem 0.9375rem;
}
body .ant-btn .ant-btn-icon {
  vertical-align: middle;
}
.ant-btn-lg {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
}
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--primary-color) !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--primary-color) !important;
}
.ant-popover {
  z-index: 2023 !important;
}
.clr_danger {
  background-color: #fce5e6 !important;
  color: #ea5455 !important;
}
.clr_primary {
  background-color: #eae8fd !important;
  color: #854DFF !important;
}
.clr_success {
  background-color: #dff7e9 !important;
  color: #28c76f !important;
}
.clr_secondary {
  background-color: #f2f2f3 !important;
  color: #a8aaae !important;
}
.header_dropdown {
  margin: -12px;
  padding: 0.25rem 0;
  width: 14rem;
  color: #5D596C;
  font-size: 0.9375rem;
}
.header_dropdown .hd_header {
  display: flex;
  margin-bottom: -0.25rem;
}
.header_dropdown .hd_header .person_box {
  margin-right: 1.25rem;
}
.header_dropdown .hd_header .person_name {
  font-weight: 600;
}
.header_dropdown .hd_line {
  border-top: 1px solid #dbdade;
  margin: 0.5rem 0;
}
.dropdown_btn {
  line-height: 1.375;
  width: calc(100% - 1rem);
  margin: 0.25rem 0.5rem;
  padding: 0.42rem 1rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown_btn:hover {
  color: var(--primary-color);
  background-color: var(--primary-color-18);
}
.dropdown_btn .anticon {
  font-size: 1.125rem;
  margin-right: 0.5rem;
}
.person_box {
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--avater-bg) !important;
  color: var(--avater-text) !important;
  user-select: none;
  cursor: pointer;
}
