.chat_list {
  flex: 1;
  .am_title {
    font-size: 1.2rem;
    color: #A5A3AE;
    padding:  0 0 0.5rem;
    margin-bottom: 1rem;
    margin-bottom: .5rem;
  }
  .am_chat {
    height: calc(100vh - 24rem);
    display: flex;
    .am_chat_list {
      width: 100%;
      // box-shadow: 0 0.25rem 1.125rem #4b465c1a;
      // border-radius: .375rem;
      display: flex;
      flex-direction: column;
      .am_chat_list_header {
        text-align: center;
        margin-bottom: .9375rem;
        .am_chat_add_btn {
          width: 100%;
          border: .0625rem solid #FFFFFF;
          border-radius: 1.25rem;
          height: 44px;
          background-color: var(--gradient-bg) !important;
          color: var(--nav-btn-text);
        }
      }
      .search_bar {
        margin-bottom: .375rem;
        .search {
          background-color:  var(--nav-input-bg);
          border-color: var(--nav-input-bd);
          border-radius: 1.25rem;
          &.mid {
            position: relative;
            svg {
              position: absolute;
              left: 4.0625rem;
              top: 50%;
              transform: translateY(-50%);
            }
            input {
              text-align: center;
            }
          }
          svg {
            color: var(--nav-input-icon);
          }
          input {
            width: calc(100% - .375rem);
            margin-left: .375rem;
            background-color: transparent;
            color: var(--nav-input-text);
            &::placeholder {
              color: var(--nav-input-placeholder);
            }
          }
        }
      }
      .am_chat_list_bottom {
        padding: .75rem 0;
        font-size: .9375rem;
        color: #6F6B7D;
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        margin-right: -0.625rem;
        padding-right: .3125rem;
        .am_chat_list_title {
          color: var(--nav-text-date);
        }
        .chat_item {
          white-space: nowrap!important;
          overflow: hidden!important;
          text-overflow: ellipsis!important;
          border-radius: .375rem;
          padding: .5rem .75rem;
          cursor: pointer;
          position: relative;
          border: 1px solid transparent;
          display: flex;
          align-items: center;
          &.chat_choosed {
            border: 1px solid #6C6C6C!important;
          }
          .anticon {
            margin-left: .375rem;
            color: var(--nav-text-sub);
          }
          .chat_icon {
            margin-right: .5rem;
            margin-left: 0;
          }
          .chat_title {
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            flex: 1 1 0%;
            max-height: 1.375rem;
            color: var(--nav-text-main);
          }
        }
      }
    }
  
  }
}