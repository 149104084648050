.pl_box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  .pl_header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #FFFFFF;
    .pl_tab_line {
      padding: 0 1.5rem;
      margin: .375rem 0 1.125rem;
      .pl_tab {
        .ant-tabs-nav {
          margin: 0;
          &::before {
            border-bottom: none;
          }
          .ant-tabs-tab {
            padding: 0 .375rem .25rem;
            color: #666666;
            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: #333333;
              }
            }
          }
          .ant-tabs-ink-bar {
            background-color: var(--primary-color);
          }
        }
      }
    }
    .pl_search {
      padding: 0 1.5rem 1.5rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .pl_s_item {
        // flex: 1;
        width: calc(25% - 1rem);
        max-width: 21.25rem;
        min-width: 10rem;
        .pl_s_title {
          margin-bottom: .375rem;
        }
        .ant-select {
          width: 100%;
        }
      }
    }
  }
  .pl_main {
    // height: calc(100vh - 22rem);
    height: 100%;
    background-color: #F7F7F7;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.5rem;
    .pl_add {
      margin-bottom: 1rem;
      text-align: center;
      button {
        width: 55rem;
        max-width: 100%;
        height: 2.75rem;
        color: var(--sub-color) !important;
        border: 1px solid var(--sub-color) !important;
        border-radius: 6px;
        background-color: transparent;
      }
    }
    .pl_list {
      // min-height: 14.5rem;
      display: grid;
      grid-template-columns: calc(25% - .75rem) calc(25% - .75rem) calc(25% - .75rem) calc(25% - .75rem);
      grid-template-rows: auto;
      grid-gap: 1rem;
      position: relative;
      .pl_list_item {
        // height: 14.5rem;
        padding: .1875rem;
        border-radius: .625rem;
        background: #FFFFFF;
        cursor: pointer;
        color: #333333;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        &.pit_choosed, &:hover {
          background: var(--card-bg);
        }
        .pl_list_item_inner {
          background-color: #FFFFFF;
          padding: 1.0625rem;
          display: flex;
          flex-direction: column;
          border-radius: .5rem;
          clip-path: inset(0 round .5rem);
          flex: 1;
          overflow: hidden;
          button {
            min-width: 1.5rem;
          }
        } 
        .pl_list_item_title_box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: .375rem;
          button {
            padding: 0;
          }
        }
        .pl_list_item_title {
          font-size: 1.125rem;
          // 单行省略
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .pl_list_item_tag {
          display: inline-block;
          padding: .375rem;
          font-size: .75rem;
          line-height: 17px;
          color: var(--tag-text);
          border-radius: 4px;
          background: var(--tag-bg);
          width: fit-content;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .pl_list_item_teaser {
          flex: 1;
          margin-top: .75rem;
          font-size: .875rem;
          height: 62.55px;
          min-height: 62.55px;
          max-height: 62.55px;
          // 多行省略
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .pl_list_bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 1.375rem;
          .pl_list_bottom_opt {
            display: flex;
          }
          button {
            padding: .4375rem .25rem;
            height: 2.125rem;
            display: flex;
            align-items: center;
            &.primary {
              padding: .4375rem .75rem;
            }
          }
        }
      }
    }
    .pl_pagination {
      width: 100%;
      min-width: 100%;
      margin-top: 1.5rem;
    }
  }
}

.prompts_modal {
  position: relative;
  .modal_opt {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
    z-index: 1;
    button {
      &+button {
        margin-left: .5rem;
      }
    }
  }
  .pl_flow_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .plf_title {
      font-size: 1.125rem;
      color: #333333;
    }
  }
}

.pl_modal_box {
  max-height: 80vh;
  overflow-y: auto;
  .pl_modal_title {
    color: #333333;
    margin: 1rem 0 .75rem;
    &:first-child {
      margin-top: 0;
    }
  }
  .pl_modal_tag_bar {
    margin-top: .5rem;
    .ant-tag {
      cursor: pointer;
    }
  }
  .pl_modal_type {
    display: flex;
    margin: 1rem 0 2rem;
    >div {
      flex: 1;
      &+div {
        margin-left: 1rem;
      }
      .ant-select {
        width: 100%;
      }
    }
  }
}

.model_preview {
  .ant-modal-footer {
    display: none;
  }
  .ant-input[disabled], .ant-select-selector {
    color: #666666 !important;
    cursor: default !important;
  }
}

@media ( max-width: 768px ) {
  .pl_box {
    .pl_header {
      .pl_tab_line {
        padding: 0 1rem;
        margin: 0 0 .5rem;
      }
      .pl_search {
        padding: 0 1rem .375rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .pl_s_item {
          width: calc(50% - 1rem);
          max-width: unset;
          min-width: 10rem;
          .pl_s_title {
            margin-bottom: 0;
          }
          .ant-select {
            margin-bottom: .25rem;
          }
        }
      }
    }
    .pl_main {
      padding: .625rem 1rem;
      .pl_add {
        margin-bottom: .625rem;
      }
      .pl_list {
        // grid-template-columns: calc(100%);
        // // grid-template-columns: calc(50% - .25rem) calc(50% - .25rem);
        // grid-gap: .5rem;
        .pl_list_item {
          .pl_list_item_inner {
            padding: .4375rem;
          }
          .pl_list_item_title_box {
            margin-bottom: .25rem;
          }
          .pl_list_item_title {
            line-height: 1.25rem;
          }
          .pl_list_item_tag {
            padding: .25rem;
          }
          .pl_list_item_teaser {
            flex: 1;
            margin-top: .25rem;
            font-size: .875rem;
          }
          .pl_list_bottom {
            margin-top: .25rem;
          }
        }
      }
      .pl_pagination {
        width: 100%;
        min-width: 100%;
        margin-top: 1rem;
      }
    }
  }
}
@media ( max-width: 900px ) {
  .pl_box {
    .pl_main {
      .pl_list {
        grid-template-columns: calc(50% - .5rem) calc(50% - .5rem);
        grid-gap: 1rem;
      }
    }
  }
}
@media ( max-width: 480px ) {
  .pl_box {
    .pl_main {
      .pl_list {
        grid-template-columns: calc(100%);
      }
    }
  }
}